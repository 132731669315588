import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/Chooser.tsx";
import '../styles/devices.min.css';
import '../styles/index.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Container = makeShortcode("Container");
const Box = makeShortcode("Box");
const Columns = makeShortcode("Columns");
const Column = makeShortcode("Column");
const Heading = makeShortcode("Heading");
const ContractsForm = makeShortcode("ContractsForm");
const StaticMapSuppliers = makeShortcode("StaticMapSuppliers");
const Breadcrumb = makeShortcode("Breadcrumb");
const BreadcrumbItem = makeShortcode("BreadcrumbItem");
const Content = makeShortcode("Content");
const AppSection = makeShortcode("AppSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Section id='/' size='medium' style={{
      backgroundColor: '#f8fff8',
      backgroundImage: 'url(https://images.unsplash.com/photo-1558871585-4c3574a1b7cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2251&q=80)',
      backgroundSize: 'cover',
      backgroundPosition: '0 20%'
    }} mdxType="Section">
  <Container className='presentation' mdxType="Container">
    <Box mdxType="Box">
      <Columns mdxType="Columns">
        <Column size={6} mdxType="Column">
          <br />
          <Heading renderAs='h1' size={2} className='handwriting' mdxType="Heading">
            <strong className='highlighted'>Achetez</strong> des matières organiques
          </Heading>
          <ContractsForm mdxType="ContractsForm" />
          <br />
        </Column>
        <Column size={6} display='relative' style={{
              position: 'relative !important'
            }} mobile={{
              display: 'hidden'
            }} mdxType="Column">
          <StaticMapSuppliers geolocation mdxType="StaticMapSuppliers" />
        </Column>
      </Columns>
    </Box>
  </Container>
    </Section>
    <Section id='steps' size='medium' mdxType="Section">
  <Container mdxType="Container">
    <Heading renderAs='h2' size={2} textAlign='center' className='handwriting' mdxType="Heading">
      Notre <strong className='highlighted'>fonctionnement</strong>
    </Heading>
    <br />
    <Breadcrumb size='medium' align='center' mdxType="Breadcrumb">
      <BreadcrumbItem active mdxType="BreadcrumbItem">
        <Content align='center' mdxType="Content">
          <p className='numbering'>1.</p>
          <Heading renderAs='a' size={3} textColor='success' mdxType="Heading">
            ✔
          </Heading>
          <p>Mandater ENIA pour votre approvisionnement</p>
        </Content>
      </BreadcrumbItem>
      <BreadcrumbItem active mdxType="BreadcrumbItem">
        <Content align='center' mdxType="Content">
          <p className='numbering'>2.</p>
          <Heading renderAs='a' size={3} textColor='success' mdxType="Heading">
            ✔
          </Heading>
          <p>Constituer votre approvisionnement</p>
        </Content>
      </BreadcrumbItem>
      <BreadcrumbItem active mdxType="BreadcrumbItem">
        <Content align='center' mdxType="Content">
          <p className='numbering'>3.</p>
          <Heading renderAs='a' size={3} textColor='success' mdxType="Heading">
            ✔
          </Heading>
          <p>Tester les matières</p>
        </Content>
      </BreadcrumbItem>
      <BreadcrumbItem active mdxType="BreadcrumbItem">
        <Content align='center' mdxType="Content">
          <p className='numbering'>4.</p>
          <Heading renderAs='a' size={3} textColor='success' mdxType="Heading">
            ✔
          </Heading>
          <p>Sélectionner les matières et contractualiser</p>
        </Content>
      </BreadcrumbItem>
    </Breadcrumb>
  </Container>
    </Section>
    <AppSection mdxType="AppSection" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      